<template>
  <div class="collection">
    <v-row align="center" justify="center" class="mb-16">
      <img class="logo mr-6" :src="letinteLogo">
      <h4>COLLECTION</h4>
    </v-row>
    <v-row class="invert" align="center" justify="center">
      <v-col cols="12" md="6" class="pa-md-12">
        <a href="https://letinte.it/collection-ss24" target="_blank"><img class="collection-img" :src="ss2024"></a>
      </v-col>
      <v-col cols="12" md="6" class="pa-md-12" align="center">
        <a href="https://letinte.it/collection-ss24" target="_blank">
          <h3 class="mb-6 small-underline">SPRING / SUMMER</h3>
          <h4 class="bold">2024</h4>
        </a>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" class="pa-md-12">
        <a href="https://letinte.it/collection-aw23/" target="_blank"><img class="collection-img" :src="aw2023"></a>
      </v-col>
      <v-col cols="12" md="6" class="pa-md-12" align="center">
        <a href="https://letinte.it/collection-aw23/" target="_blank">
          <h3 class="mb-6 small-underline">FALL / WINTER</h3>
          <h4 class="bold">2023</h4>
        </a>
      </v-col>
    </v-row>
    <v-row class="invert" align="center" justify="center">
      <v-col cols="12" md="6" class="pa-md-12">
        <a href="https://letinte.it/collection-ss23/" target="_blank"><img class="collection-img" :src="ss2023"></a>
      </v-col>
      <v-col cols="12" md="6" class="pa-md-12" align="center">
        <a href="https://letinte.it/collection-ss23/" target="_blank">
          <h3 class="mb-6 small-underline">SPRING / SUMMER</h3>
          <h4 class="bold">2023</h4>
        </a>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" class="pa-md-12">
        <a href="https://letinte.it/collection-aw22/" target="_blank"><img class="collection-img" :src="aw2022"></a>
      </v-col>
      <v-col cols="12" md="6" class="pa-md-12" align="center">
        <a href="https://letinte.it/collection-aw22/" target="_blank">
          <h3 class="mb-6 small-underline">FALL / WINTER</h3>
          <h4 class="bold">2022</h4>
        </a>
      </v-col>
    </v-row>
    <v-row class="invert" align="center" justify="center">
      <v-col cols="12" md="6" class="pa-md-12">
        <a href="https://letinte.it/collection-ss22/" target="_blank"><img class="collection-img" :src="ss2021"></a>
      </v-col>
      <v-col cols="12" md="6" class="pa-md-12" align="center">
        <a href="https://letinte.it/collection-ss22/" target="_blank">
          <h3 class="mb-6 small-underline">SPRING / SUMMER</h3>
          <h4 class="bold">2022</h4>
        </a>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mb-12 mb-md-0">
      <v-col cols="12" md="6" class="pa-md-12">
        <a href="https://letinte.it/" target="_blank"><img class="collection-img" :src="aw2020"></a>
      </v-col>
      <v-col cols="12" md="6" class="pa-md-12" align="center">
        <a href="https://letinte.it/" target="_blank">
          <h3 class="mb-6 small-underline">FALL / WINTER</h3>
          <h4 class="bold">2021</h4>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import letinteLogo from '@/assets/img/le-tinte.png'
import aw2023 from '@/assets/img/aw-2023.jpg'
import aw2022 from '@/assets/img/molla.jpg'
import aw2020 from '@/assets/img/aw-2020.jpg'
import ss2021 from '@/assets/img/mustang-compressed.jpg'
import ss2023 from '@/assets/img/ss-2023.jpg'
import ss2024 from '@/assets/img/ss-2024.jpg'

export default {
  data () {
    return {
      letinteLogo,
      aw2023,
      aw2022,
      aw2020,
      ss2021,
      ss2023,
      ss2024
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  height: 60px;
}

.collection-img {
  width: 100%;
}

.invert {
  flex-direction: row-reverse;

  @include breakpoint('sm-and-down') {
    flex-direction: row;
  }
}
</style>
